import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import { logIt, empty } from '__globals/global-functions';

export function checkPromocracyVariation( promotionConfig, newVars ) {
    if ( isPromocracyOn(promotionConfig) ) {
        let promocracyParticipant = retrievePromocracyParticipant( promotionConfig, newVars["source"] );
        if ( !empty(promocracyParticipant) ) {
            newVars["promocracyOn"] = true;
            newVars["promocracyKey"] = promocracyParticipant["key"];
            newVars["promocracyLogoImage"] = promocracyParticipant["logoImage"];
            newVars["promocracyDisplayName"] = promocracyParticipant["displayName"];
            newVars["promocracyOverallSponsorAddOn"] = retrievePromocracyOverallSponsorAddOn( promotionConfig );
        } else {
            newVars["promocracyOn"] = false;
            newVars["promocracyDisplayName"] = retrievePromocracyDefaultDisplayName( promotionConfig );
            newVars["promocracyOverallSponsorAddOn"] = "";
        }
    }
    return newVars;
}

export function isPromocracyOn( promoConfig ) {
    if ( promoConfig?.config?.variations?.promocracy?.promocracyOn === true ) {
        return true;
    }
    return false;
}

export function retrievePromocracyParticipant( promoConfig, participantKey ) {
    let foundParticipant;
    if ( !empty(participantKey) ) {
        let participants = promoConfig?.config?.variations?.promocracy?.participants;
        let items = participants.map((participant, key) => {
            if (participant.key.toUpperCase() == participantKey.toUpperCase()) {
                foundParticipant = participant;
            }
        });
    }
    return foundParticipant;
}

export function retrievePromocracyDefaultDisplayName( promoConfig ) {
    if ( !empty(promoConfig?.config?.variations?.promocracy?.defaultDisplayName) ) {
        return promoConfig?.config?.variations?.promocracy?.defaultDisplayName;
    }
    return "The Promotion Sponsor";
}

export function retrievePromocracyOverallSponsorAddOn( promoConfig ) {
    if ( !empty(promoConfig?.config?.variations?.promocracy?.overallSponsorAddOn) ) {
        return promoConfig?.config?.variations?.promocracy?.overallSponsorAddOn;
    }
    return "";
}


